import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w01EAT09Ueberraschung = () => (
  <Layout>
    <SEO title="w01EAT09Ueberraschung" />

    <h1 id="ueberschrift">Eigenorganisation II</h1>
    <h2>Wie plane ich „Unvorhergesehenes“? &emsp;  ©&nbsp;1990</h2>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w01EAT09Ueberraschung&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p>
        Die in *Eigenorganisation* kennengelernten Methoden wirken nur "ab-und-zu"?
        Teilweise passiert es immer noch, dass "xy" vergessen wird oder "z" vergeblich gesucht wird?
        Sie haben das Gefühl, dass zu viele Gedanken in Ihrem Kopf sind?
      </p>
      <p>  
        In der Theorie lassen sich die Techniken super anwenden, doch in der Praxis gibt es
        zu viele überraschende Wendungen und Ereignisse, welche gerne einmal in die *Eigenorganisation* pfuschen.
      </p>
      <p>
        Lassen Sie uns erneut zusammen arbeiten und bringen Sie Ihre neuen Erfahrungen in diesen zweiten
        Teil ein. Gemeinsam können wir die Methoden verfeinern und die Techniken vertiefen. Sie können Tricks lernen,
        mit denen Sie "Unvorhergesehenes" zu einem gewissen Grad planen und in Ihrer Aufgaben-Tabelle anzeigen können.
      </p>
      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Vortrag (+Mitmachkurs)
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Tisch pro Teilnehmer + 1 Tisch extra<br/>
        Materialbedarf: keiner
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 1 Tisch
      </p>
      <p>
        Voraussetzungen: + Basiskurs der Eigenorganisation +
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w01EAT09Ueberraschung
